@import "@variables"; @import "@mixin";
.reviewItems {
  width: 100%;
  height: 100%;
  position: initial;
  margin-top: 32px;
  li {
    display: flex;
    justify-content: center;
    height: 100%;
  }
}

.prev {
  position: absolute;
  left: 30px;
  font-size: 100px;
  width: 15px;
  height: 15px;
  border-bottom: 3px solid $black-2;
  border-left: 3px solid $black-2;
  transform: rotate(45deg);
  cursor: pointer;
}

.next {
  position: absolute;
  right: 30px;
  font-size: 100px;
  width: 15px;
  height: 15px;
  border-bottom: 3px solid $black-2;
  border-left: 3px solid $black-2;
  transform: rotate(-135deg);
  cursor: pointer;
}

@include screen-tablet {
  .item {
    max-width: 200px;
  }
}

@include screen-mobile {
  .section {
    margin-top: 0;
  }

  .item {
    max-width: 250px;
    margin: 0 30px;
  }

  .prev {
    left: 20px;
  }

  .next {
    right: 20px;
  }
}
