@import "@variables"; @import "@mixin";
.wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 48px 0;
}

.usageSection {
  margin-top: 16px;
}

@include screen-mobile {
  .wrap {
    margin: 0 10px 24px;
    margin: 0 0 24px;
  }
}
