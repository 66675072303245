@import "@variables"; @import "@mixin";
.item {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  padding: 16px 18px;
  margin: 0 20px;
  border-radius: 10px;
  background-color: $white-1;
  &Info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
    &UserName {
      font-size: $font-15px;
      font-weight: bold;
      line-height: 18px;
    }
  }
  &Description {
    font-weight: 400;
    line-height: 24px;
    color: $black-2;
    white-space: pre-line;
  }
}
