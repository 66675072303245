@import "@variables"; @import "@mixin";
.mainBannerSection {
  @include max-width-section;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  margin: 16px auto 0;
  width: 100%;
  min-height: 215px;
}

.mainBanners {
  width: 100%;
  min-height: 215px;

  &Dots {
    bottom: 12px;
    grid-column-gap: 10px;

    &Item {
      background-color: $white-1;
      border: 1px solid $gray-8;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      cursor: pointer;

      &Link {
        display: block;
        &ImgSp {
          display: none;
        }
        &ImgPc {
          width: 100%;
        }
      }

      &Active {
        border: 2px solid $theme-color;
        background-color: $blue-4;
      }
    }
  }
}

.storeSection {
  margin-top: 0;
}

.bannerPC, .bannerMobile {
  min-height: 215px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bannerMobile {
  display: none;
}

@include screen-mobile {
  .mainBannerSection {
    margin-top: 16px;
  }

  .bannerPC {
    display: none;
  }

  .bannerMobile {
    display: block;
    min-height: 195px;
  }

  .mainBannerSection {
    min-height: 195px;
  }

  .mainBanners {
    min-height: 195px;
  }
}