@import "@variables"; @import "@mixin";
.usageItems {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 900px;
  margin-top: 32px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 256px;
  background-color: $white-1;
  border-radius: 10px;
  position: relative;
  margin-right: 35px;
  padding: 30px 9px;
  &Number {
    position: absolute;
    top: -20px;
    left: 0;
  }
  > img {
    margin-bottom: 10px;
  }
  &Text {
    font-weight: 700;
    line-height: 24px;
    white-space: pre-line;
    text-align: center;
  }
  &:last-child {
    margin-right: initial;
  }
}

@include screen-mobile {
  .usageItems {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 838px;
  }

  .item {
    width: 100%;
    max-width: 256px;
    margin-right: initial;
    margin-bottom: 47px;
    &Text {
      font-weight: 700;
      line-height: 24px;
      white-space: pre-line;
      text-align: center;
    }
    &:last-child {
      margin-bottom: initial;
    }
  }
}
