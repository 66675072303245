@import "@variables"; @import "@mixin";
.featureItems {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  margin-top: 32px;
  &Section {
    display: inherit;
    flex-direction: row;
  }
}

.item {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: $white-1;
  border-radius: 10px;
  padding: 24px 16px;
  margin: 15px;
  &Image {
    max-width: 66px;
    margin-right: 10px;
  }
  &Title {
    font-weight: 700;
    font-size: $font-20px;
    line-height: 28px;
    margin-bottom: 10px;
  }
  &Desc {
    width: 100%;
    font-size: $font-12px;
    line-height: 20px;
    white-space: pre-line;
  }
}

@include screen-mobile {
  .section {
    margin-top: 0;
  }

  .featureItems {
    &Section {
      display: initial;
      flex-direction: initial;
      margin-bottom: 18px;
      &:last-child {
        margin-bottom: initial;
      }
    }
  }

  .item {
    flex-direction: column;
    align-items: initial;
    padding: 16px 12px;
    margin: initial;
    margin-bottom: 10px;
    &Heading {
      display: inherit;
      align-items: center;
      margin-bottom: 20px;
      > img {
        width: 100%;
        max-width: 100px;
        margin-right: 25px;
      }
    }
    &Desc {
      white-space: initial;
    }
    &:last-child {
      margin-bottom: initial;
    }
  }
}
