@import "@variables"; @import "@mixin";
.costItems {
  width: 100%;
  max-width: 900px;
  margin: 32px auto;

  &Plan {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
  }
}

.item {
  background-color: $white-1;
  border-radius: 10px;
  padding: 16px;

  &:first-child {
    margin-right: 27px;
  }

  &Heading {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &Description {
    font-size: $font-13px;
    line-height: 21px;
  }

  &PriceBox {
    display: flex;
    flex-direction: column;
    margin-right: 10px;

    &Title {
      font-weight: 700;
      line-height: 26px;
      margin-bottom: 5px;
    }
  }

  &Price {
    font-weight: 700;
    font-size: $font-38px;
    line-height: 48px;

    >span {
      font-weight: bold;
      font-size: $font-20px;
      line-height: 28px;
    }
  }
}

.costNote {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $gray-12;

  &Title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  &Description {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
  }
}

@include screen-mobile {
  .costItems {
    max-width: initial;

    &Plan {
      flex-direction: column;
      margin-bottom: 7px;
    }
  }

  .item {
    padding: 15px 10px;

    &:first-child {
      margin-right: initial;
      margin-bottom: 13px;
    }

    &Heading {
      >img {
        width: 100%;
        max-width: 146px;
      }
    }

    &PriceBox {
      margin-right: 10px;

      &Title {
        font-size: $font-12px;
        line-height: 20px;
      }
    }

    &Price {
      font-size: $font-32px;
      line-height: 40px;

      >span {
        font-size: $font-16px;
        line-height: 24px;
      }
    }
  }
}