@import "@variables"; @import "@mixin";
.ownerSection {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  margin-top: 41px;
  &Box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 2px solid $theme-color;
    width: 100%;
    max-width: 620px;
    height: 107px;
    padding: 0 33px;
    > img {
      margin-right: 12px;
    }
    &Title {
      font-weight: bold;
      font-size: $font-20px;
      line-height: 23px;
      color: $theme-color;
      margin-right: 20px;
      white-space: pre-line;
    }
    &Button {
      height: 50px;
      border-radius: 10px;
      background-color: $theme-color;
      padding: 0 13px;
      cursor: pointer;
      > a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white-1;
        font-weight: 700;
      }
      &:hover {
        background-color: $theme-color-hover;
      }
      &:active {
        background-color: $theme-color-active;
      }
    }
  }
}

@include screen-mobile {
  .ownerSection {
    margin: 21px 10px 0;

    &Box {
      max-width: initial;
      height: 81px;
      padding: 0 11px;
      &Title {
        font-size: initial;
        line-height: 24px;
        margin-right: 5px;
      }
      &Button {
        display: flex;
        align-items: center;
        padding: 10px 14px;
        > a {
          display: inline;
          align-items: initial;
          font-size: $font-12px;
          white-space: pre-line;
        }
      }
    }
  }
}
