@import "@variables"; @import "@mixin";
.searchCurrent {
  display: none;
}

.searchArea {
  width: 100%;
  background: $blue-4;
  border-radius: 10px;
  padding: 26px 64px;
  &Content {
    width: inherit;
    max-width: 864px;
    margin: auto;
  }
}

.search {
  &Section {
    @include max-width-section;
    display: inherit;
    justify-content: space-between;
    flex-direction: column;
    background-image: url('/top.webp');
    background-position: right 0;
    background-repeat: no-repeat;
    margin: 0 auto;
    padding-top: 100px;
    &Heading {
      width: 100%;
      max-width: 800px;
      margin: 0 auto 50px;
      padding-left: 50px;
      &Title {
        font-weight: bold;
        font-size: $font-48px;
        color: $theme-color;
        white-space: pre-line;
      }
      &SubTitle {
        font-size: $font-20px;
        font-weight: bold;
        line-height: 28px;
        margin-top: 10px;
      }
    }

    &CountryTwn {
      background-image: url('/top_twn.webp');
    }
  }
}

@include screen-mobile {
  .searchCurrent {
    display: block;
    margin: 20px 0;
    text-align: center;
  }

  .searchArea {
    height: initial;
    padding: 16px;
    margin: initial;
    &Content {
      max-width: initial;
    }
  }

  .search {
    &Section {
      padding-top: 30px;
      margin: 0 10px;
      width: auto;
      box-sizing: border-box;
      background-size: initial;
      background-position: right 20%;
      background-image: url('/top_sp.webp');
      &Heading {
        justify-content: initial;
        padding-left: initial;
        margin: 0 auto 70px;
        &Title {
          font-size: $font-32px;
        }
        &SubTitle {
          white-space: pre-line;
          font-size: $font-16px;
          color: $gray-2;
          line-height: 24px;
        }
      }
      &CountryTwn {
        background-image: url('/top_sp_twn.webp');
      }
    }
  }
}
