@import "@variables"; @import "@mixin";
.countryItems {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 11px;
  width: 100%;
  max-width: 900px;
  margin-top: 32px;
}

.item {
  width: 100%;
  height: 184px;
  border-radius: 10px;
  overflow: hidden;

  &Overlay {
    width: 100%;
    height: 100%;
    background-color: rgba($white-1, 0.05);

    .areaNameLink {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $font-25px;
      color: $white-1;
      width: 100%;
      height: 100%;
      line-height: 29px;
      font-weight: 700;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}

@include screen-mobile {
  .countryItems {
    max-width: initial;
    grid-template-columns: repeat(1, 1fr);
  }

  .item {
    height: 130px;
    &Overlay {
      .areaNameLink {
        font-size: $font-20px;
        line-height: 28px;
      }
    }
  }
}
