@import "@variables"; @import "@mixin";
.storeSection {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 41px auto 0;
  &Area {
    display: inherit;
    justify-content: inherit;
    flex-direction: column;
    margin-right: 20px;
  }
  &Title {
    font-weight: bold;
    font-size: $font-15px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  &Banner {
    > img {
      cursor: pointer;
      &:first-child {
        margin-right: 5px;
      }
    }
  }
  &Image {
    height: 150px;
  }
}

@include screen-mobile {
  .storeSection {
    margin: 32px auto 0;
    &Title {
      font-size: initial;
      line-height: 24px;
      margin-bottom: 7px;
      white-space: pre-line;
    }
    &Banner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      > img {
        &:first-child {
          margin-right: initial;
          margin-bottom: 7px;
        }
      }
    }
  }
}
